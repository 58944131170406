import React from 'react'
import HeaderEducation from '../../components/Header/HeaderEducation';
import { Layout, Row, Col } from "antd";
import PrincipalImage from "../../media/education.png";
import Objetivo from "../../media/objt4.gif";
import Card from "../../components/Card/Card";
import Contacto from "../../components/Contacto/Contacto";
import Matematicas from "../../media/matematicas.jpg";
import Lectura from "../../media/lectura.jpg";
import './Principal.css';

const {Content} = Layout;

const Principal = () => {
   

    return (
            <>
            <Layout>
              <Content>
                {/*Navigation Menu*/}
                <HeaderEducation/>

                <Row className="titulo row__decoration">
                    <h1>Educación de calidad</h1>
                </Row>

                <Row justify="center" className="img__principal">
                    <Col xs={24}>
                        <img src={PrincipalImage} alt="Educación" />
                    </Col>
                </Row>

                <Row className="titulo subtitulo row__decoration">
                    <h2>Sobre nosotros</h2>
                </Row>

                <Row>
                    <div className="contenedor__nosotros">
                        <p className="parrafo__nosotros">
                           Inspirados por el Objetivo número 4 del Programa de las Naciones Unidas para el Desarrollo (PNUD), 
                           nos preocupamos por promover el conocimiento hacia cualquier persona de forma gratuita y dinámica. 
                        </p>
                        <img src={Objetivo} alt="Educación de calidad" className="img-objetivo"/>
                    </div>
                </Row>
                
                <Row className="titulo subtitulo row__decoration">
                    <h2>Nuestros Servicios</h2>
                </Row>

                <Row justify="center">
                    <div className="contenedor__cards">
                        <Card letra="M" titulo="Curso de Matemáticas" description="Aprende todo sobre matemáticas con nosotros, cursos básicos hasta avanzados." img={Matematicas}/> 
                        <Card letra="L" titulo="Curso de Lingüistica" description="Aprende todo sobre lingüistica con nosotros, cursos básicos hasta avanzados." img={Lectura} />
                    </div>
                </Row>

                <Row className="titulo subtitulo row__decoration">
                    <h2>Contáctanos</h2>
                </Row>

                <Row>
                    <Contacto />
                </Row>
              </Content>
            </Layout>
            <Row>
                <div className="footer">
                    <p>Educación de calidad, todos los derechos reservados &copy;</p>
                </div>
            </Row>
            </>
    )
}

export default Principal
