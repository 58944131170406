import React from "react";
import Principal from './pages/Principal/Principal';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Linguistica from "./pages/Linguistica/Linguistica";
import './index.css';


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Principal /> 
        </Route>
        <Route path="/linguistica">
          <Linguistica />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
