import React, { useState } from 'react'
import HeaderEducation from '../../components/Header/HeaderEducation';
import Modal from "../../components/DialogError/Modal";
import Correcto from "../../components/DialogCorrect/Correct";
import TextField from '@material-ui/core/TextField';
import { Layout, Row } from "antd";
import './Linguistica.css';
import { Button } from '@material-ui/core';
import {useHistory} from "react-router-dom";

const {Content} = Layout;


const Linguistica = () => {

    const history = useHistory();

    //Modal
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClose = () => {
    setOpen(false);
    };

    const handleClose2 = () =>{
        setOpen2(false);
        history.replace('/')
    }

    const [primera, setPrimera] = useState('');
    const [segunda, setSegunda] = useState('');
    const [tercera, setTercera] = useState('');

    const onFinish = () =>{
        if(!primera || !segunda || !tercera){
            setOpen(true);
        }

        if(primera === 'bienvenido' && segunda === 'manzana' && tercera === 'cuaderno') {
            setOpen2(true);
        }
    }

    return (
        <>
        <Layout>
            <Modal open={open} handleClose={handleClose} />
            <Correcto open={open2} handleClose={handleClose2} />
            <Content>
                {/*Navigation Menu*/}
                <HeaderEducation/>
                <Row className="titulo__linguistica row__decoration">
                    <h1>Lingüística</h1>
                </Row>

                <Row>
                    <p className="parrafo__instrucciones"><b>Ejercicio 1. Ordene las palabras según corresponda</b></p>
                </Row>
                
                <Row>
                    <div className="contenedor__ejercicios">
                        <p className="parrafo__ejercicio">
                            Palabra utilizada cuando alguien llega a algún lugar: 
                        </p>
                        <p className="parrafo__ejercicio">
                            <b>ivbenonied</b>
                        </p>
                        <TextField id="primera" label="Outlined" variant="outlined" onChange={(e)=>{setPrimera(e.target.value)}}/>
                    </div>
                </Row>

                <Row>
                    <div className="contenedor__ejercicios">
                        <p className="parrafo__ejercicio">
                            Fruta roja que generalmente es muy dulce: 
                        </p>
                        <p className="parrafo__ejercicio">
                            <b>anazman</b>
                        </p>
                        <TextField id="segunda" label="Outlined" variant="outlined" onChange={(e)=>{setSegunda(e.target.value)}}/>
                    </div>
                </Row>

                <Row>
                    <div className="contenedor__ejercicios">
                        <p className="parrafo__ejercicio">
                            Herramienta que nos ayuda a escribir nuestros apuntes: 
                        </p>
                        <p className="parrafo__ejercicio">
                            <b>drnoeacu</b>
                        </p>
                        <TextField id="tercera" label="Outlined" variant="outlined" onChange={(e)=>{setTercera(e.target.value)}}/>
                    </div>
                </Row>
                <Row>
                    <div className="revisar">
                        <Button variant="contained" color="secondary" onClick={onFinish}>Comprobar</Button>
                    </div>
                </Row>
            </Content>
        </Layout>
        </>
    )
}

export default Linguistica
